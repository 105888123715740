body {
  background: #f3f9fb;
}

.Note {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 250px;
  margin: 10px;
  background-color: #f3ecd3;
  padding: 15px 15px;
  box-shadow: 10px 10px 10px #dfdfdf;
  transition: background-color 0.25s;
}

.AddNote {
  @extend .Note;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #dfdfdf;
}

.Plus {
    border: 1px dotted #dfdfdf;
    width: 3px;
    height: 3px;
    background-color: #c5c5c5;
    box-sizing: border-box;
    transform: scale(21);
}

.Close {
  cursor: pointer;
  align-self: flex-end;
  background: url('/images/close-icon.png') center;
  background-size: cover;
  min-height: 30px;
  min-width: 30px;

}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.TextArea {
  font-family: 'Noto Sans', sans-serif;
  font-size: 20px;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: #474f85;
  width: 100%;
  height: 100%;
  resize: none;
  outline: none;
}

.Title {
  background-color: rgba(0, 0, 0, 0);
  @extend .TextArea;
  font-size: 26px;
  height: 30px;
}

.NoteContainer {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Colors {
  background-color: rgba(0, 0, 0, 0);
  color: black;
  outline: none;
  height: 40px;
  width: 100px;
  border: none;
  padding: 5px;
  font-size: 15px;
}